import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
//import 'uikit/dist/css/uikit.css'

import "./ctaList.scss"
import { GatsbyImage } from "gatsby-plugin-image"




// const Button = loadable(() => import("../button/button"));

import Button from "../button/button";

const CTAList = ({ title, subtitle, contents, cardStyle, bgColor, searchUrl, cardType }) => {

    const [showMore, setShowMore] = useState(3);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    let postsPerPage = 3;

    const handleClick = () => {

        setShowMore((prevPosts) => prevPosts + postsPerPage);
    };

    useEffect(() => {

        setShowMore(3);

    }, [searchUrl])

    return (
        <>
            <div className="cta-list-container" style={bgColor ? {backgroundColor: bgColor} : {}}>
                <div className="cta-list-content">
                    <div className="additional-options-container uk-align-center " id="additional-options">
                        
                            {
                                title ? 
                                    <div className="cta-list-header-text">
                                        <h2 id="cta-list-container-title" className="uk-text-center">{title}</h2> : ""
                                    </div> : ""
                            }
                            {
                                subtitle ? <div className="uk-text-center additional-options-subtitle">{subtitle}</div> : ""
                            }
                        
                        <div className={contents.length != 0 ? "uk-child-width-1-3@xl uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1@xs uk-grid cta-card-grid" : "uk-child-width-1-3@xl uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1@xs uk-grid no-results-message"} uk-grid="true">
                            {

                                contents.length != 0 ? contents.slice(0, showMore).map((item, index) => {

                                    const descriptionWithoutAsterisks = item.Description ? item.Description.length > 250 ?
                                    item.Description = item.Description.substring(0, 250).replace(/\*\*/g, '') + '...'

                                    : item.Description.replace(/\*\*/g, '') : "";

                                    return (

                                        <div key={index} className={`cta-card uk-animation-scale-up article-link`}>
                                            <div className="uk-card uk-card-default cta-card">
                                                <div className="uk-card-media-top">
                                                    {
                                                        item.Image?.gatsbyImageData ? <GatsbyImage className="cta-card-image" image={item.Image.gatsbyImageData} alt=""></GatsbyImage> : ""
                                                    }
                                                    {
                                                        item.Image?.localFile?.childImageSharp.gatsbyImageData ? <GatsbyImage className="cta-card-image" image={item.Image.localFile.childImageSharp.gatsbyImageData} alt="" ></GatsbyImage> : ""
                                                    }
                                                    {
                                                        item.ImageURL ? <GatsbyImage image={item.ImageURL} alt={""}></GatsbyImage> : ""

                                                    }
                                                </div>
                                                <div className="uk-card-body cta-card-body">
                                                    {
                                                        item.CardType === "news" ?  <div className="align-cards"><Link to={`/${item.created_at?.split('T')[0].replace(/-/g, '/')}/${item.Slug.toLowerCase()}`} className="uk-card-title cta-card-title">
                                                            {item.Title.length > 50 ?
                                                            item.Title = item.Title.substring(0, 50) + '...'
                                                            : item.Title}
                                                            </Link></div>  :  
                                                        item.CardType === "event" ? <div className="align-cards"><Link to={`/events/${item.Slug.toLowerCase()}`} className="uk-card-title cta-card-title">
                                                            {item.Title.length > 50 ?
                                                            item.Title = item.Title.substring(0, 50) + '...'
                                                            : item.Title}
                                                            </Link></div> : 
                                                        item.CardType === "shop" ?  <div className="align-cards"><Link to={`/shop/${item.Slug.toLowerCase()}`} className="uk-card-title cta-card-title">
                                                            {item.Title.length > 50 ?
                                                            item.Title = item.Title.substring(0, 50) + '...'
                                                            : item.Title}
                                                            </Link></div> : 
                                                        
                                                        <p className="uk-card-title cta-card-title">{item.Title}</p>

                                                    }
                                                    { 
                                                        item.CardType && item.CardType == 'event' ? item.FromDate && item.ToDate ? 


                                                            <p className="date">{new Date(item.FromDate).toLocaleDateString('en-US', options)} - {new Date(item.ToDate).toLocaleDateString('en-US', options)}</p> 
                                                        
                                                        : <p className="date">{item.FromDate}</p> :
                                                        item.CardType && item.CardType == 'news' ? item.Author ? <p className="date">by {item.Author} | News</p> : "" : ""
                                                    }
                                                    <p className="description-height">{
                                                        descriptionWithoutAsterisks
                                                    }</p>
                                                    {
                                                        item.Description ? 

                                                        item.CardType && item.CardType == 'event' ?  <Link to={`/events/${item.Slug.toLowerCase()}`} className="find-out-more">find out more »</Link> : 
                                                        item.CardType && item.CardType == 'news' ?  <Link to={`/${item.created_at?.split('T')[0].replace(/-/g, '/')}/${item.Slug.toLowerCase()}`} className="find-out-more" >find out more »</Link> : 
                                                        item.CardType && item.CardType == 'shop' ? <Link to={`/shop/${item.Slug.toLowerCase()}`} className="find-out-more" >find out more »</Link> 

                                                        : "" : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )


                                }) : 
                                
                                        <div className="no-content-list">
                                           
                                                <h2>{(cardType == 'event')?'We have lots of exciting events coming up – please visit our socials for all the information and follow us to keep up to date.':'No Results Found'}</h2>
                                                <p>{(cardType == 'event')?'':'The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.'}</p>
                                            
                                        </div>
                                  
                                
                            }
                        </div>
                        <div className="show-more">
                            {
                                showMore < contents.length ? 
                                <Button
                                    id={"sign-up-submit"}
                                    btnClass={"primary"}
                                    btnType={"primary"}
                                    label={'Show more'}
                                    isSubmit={"false"}
                                    clickAction={handleClick}
                                >
                                </Button> : ""
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CTAList